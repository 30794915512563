(function () {
  'use strict';

  class ResultsPageCtrl {
    constructor($rootScope, $filter, $window, ModuleSet, Classrooms, CanvasZoom, ResultsExpanded, Utils, $scope) {
      let vm = this;
      vm.currentUser = $rootScope.user;
      vm.$scope = $scope;
      vm.ModuleSet = ModuleSet;
      vm.Utils = Utils;
      vm.Classrooms = Classrooms;
      vm.ResultsExpanded = ResultsExpanded;
      vm.CanvasZoom = CanvasZoom;
      vm.$filter = $filter;
      vm.classroomId = $scope.classroomId || vm.currentUser.classroom_id;
      vm.moduleId = $scope.moduleId;
      vm.pieChartOptions = {
        chart: {
          type: 'pieChart',
          height: 500,
          legendPosition: 'right',
          showLabels: false,
          x: function (d) {
            return d.key;
          },
          y: function (d) {
            return d.y;
          },
          duration: 500,
          labelThreshold: 0.01,
          labelSunbeamLayout: true,
          legend: {
            margin: {
              top: 95
            }
          }
        }
      };
      vm.barChartOptions = {
        chart: {
          type: 'discreteBarChart',
          height: 450,
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          showValues: true,
          valueFormat: function (d) {
            return $window.d3.format(',d')(d);
          },
          duration: 500,
          xAxis: {
            axisLabel: 'Answer'
          },
          yAxis: {
            axisLabel: 'Count',
            axisLabelDistance: -10
          }
        }
      };
      vm.init();
    }
    init() {
      const vm = this;
      vm.pagerCounter = 0;
      vm.currentModule = null;
      vm.setNavigationStatus(false);
      vm.moduleSetIndex();
    }
    refresh() {
      const vm = this;
      vm.getResults(true);
    }
    moduleSetIndex() {
      const vm = this;
      vm.ModuleSet.moduleSetIndex()
        .then((data) => {
          vm.moduleSets = data.module_sets;
          if (vm.moduleSets && !vm.module_set) {
            vm.module_set = vm.moduleSets[0];
          }
          vm.selectModuleSet();
        });
    }
    getResults(hardRefresh = false) {
      const vm = this,
          bodyResquest = {id: vm.classroomId, module_session_id: vm.currentModule.id};
      vm.pollsCounter = 0;
      if (hardRefresh) {
        bodyResquest['options[force]'] = true;
      }
      vm.loading = true;
      vm.Classrooms.results(bodyResquest)
        .then((data) => {
          vm.resultsPayload = data;
          const results = vm.resultsPayload.results;
          results.discussion_entries = vm.Utils.matrixSetup(results.discussion_entries, 3);
          vm.setPoll();
          vm.loading = false;
        });
    }
    zoom(e, module_sessions, group) {
      const vm = this;
      vm.ResultsExpanded.show(e, {module_sessions, group});
    }
    setupGraphs() {
      const vm = this,
          barData = vm.$filter('filter')(vm.currentPoll.poll_questions, {question_type: 'bar_chart'}),
          pieData = vm.$filter('filter')(vm.currentPoll.poll_questions, {question_type: 'pie_chart'}),
          barChartPayload = barData && barData[0],
          pieChartPayload = pieData && pieData[0];
      vm.barChartTitle = barChartPayload && barChartPayload.text;
      vm.pieChartTitle = pieChartPayload && pieChartPayload.text;
      vm.barChartData = [{key: 'Results', values: []}];
      vm.pieChartData = [];
      angular.forEach(barChartPayload.poll_question_options, function (poll_question_option) {
        vm.barChartData[0].values.push({label: vm.$filter('htmlToPlainText')(poll_question_option.text), value: poll_question_option.meta_responses.count || 0});
      });
      angular.forEach(pieChartPayload.poll_question_options, function (poll_question_option) {
        vm.pieChartData.push({key: vm.$filter('htmlToPlainText')(poll_question_option.text), y: poll_question_option.meta_responses.count || 0});
      });
    }
    setPoll() {
      const vm = this;
      vm.currentPoll = vm.resultsPayload.results.polls[vm.pollsCounter];
      vm.setupGraphs();
      vm.checkPollTree();
    }
    checkPollTree() {
      const vm = this,
          polls = vm.resultsPayload.results.polls;
      if (polls[vm.pollsCounter + 1]) {
        vm.nextPollInvalid = false;
      } else {
        vm.nextPollInvalid = true;
      }
      if (polls[vm.pollsCounter - 1]) {
        vm.prevPollInvalid = false;
      } else {
        vm.prevPollInvalid = true;
      }
    }
    nextPoll() {
      const vm = this;
      vm.pollsCounter++;
      vm.setPoll();
    }
    previousPoll() {
      const vm = this;
      vm.pollsCounter--;
      vm.setPoll();
    }
    setCurrentModule(first = false) {
      const vm = this;
      if (!vm.currentModule && vm.currentUser.group_id && vm.moduleId) {
        let selected_module_session_id = vm.$scope.resourceNode.selected_module_session_id;
        if (first && selected_module_session_id) {
          const selected_module_session = vm.$filter('filter')(vm.module_set.module_sessions, {id: selected_module_session_id});
          vm.currentModule = selected_module_session[0];
          vm.pagerCounter = vm.module_set.module_sessions.indexOf(vm.currentModule);
        } else {
          vm.selectDefaultModuleSessionIndex();
        }
      }
      vm.currentModule = vm.module_set.module_sessions[vm.pagerCounter];
      vm.getResults();
      vm.checkTree();
    }
    checkTree() {
      const vm = this,
          docs = vm.module_set.module_sessions;
      if (docs[vm.pagerCounter + 1]) {
        vm.cannotGoNext = false;
      } else {
        vm.cannotGoNext = true;
      }
      if (docs[vm.pagerCounter - 1]) {
        vm.cannotGoBack = false;
      } else {
        vm.cannotGoBack = true;
      }
    }
    selectModuleSet() {
      const vm = this;
      vm.module_set.module_sessions = null;
      vm.Classrooms.getModuleSession({id: vm.classroomId, module_set_id: vm.module_set.id})
        .then((data) => {
          vm.module_set.module_sessions = data.module_sessions;
          if (vm.module_set.module_sessions.length > 0) {
            vm.setCurrentModule(true);
          }
        });
    }
    setNavigationStatus(status) {
      const vm = this;
      vm.cannotGoBack = status;
      vm.cannotGoNext = status;
    }
    selectDefaultModuleSessionIndex() {
      const vm = this;
      angular.forEach(vm.module_set.module_sessions, (session, index) => {
        angular.forEach(session.group_module_documents, (gmd) => {
          if (gmd.module_document.id === vm.moduleId && gmd.group.id === vm.currentUser.group_id) {
            vm.pagerCounter = index;
            return;
          }
        });
      });
    }
    prev() {
      const vm = this;
      vm.pagerCounter--;
      vm.setCurrentModule();
    }
    next() {
      const vm = this;
      vm.pagerCounter++;
      vm.setCurrentModule();
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:ResultsPageCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('ResultsPageCtrl', ResultsPageCtrl);
}());
